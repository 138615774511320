import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import TeamSlider from '../Slider/TeamSlider';
import Spacing from '../Spacing';
import ServiceList from '../ServiceList';
import Leader from '../leadership/leadership';

export default function AboutPage() {
  pageTitle('About');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container mt-n5">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="Diving into Our Agency's Odyssey"
              subtitle="About Our Agency"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              We are your partners in digital transformation, dedicated to empowering businesses of all sizes – from dynamic startups to established enterprises – with custom-crafted digital solutions.
              <br/>
              <br/>
              Our mission is to help you enhance your digital footprint, optimize operations, and drive unprecedented growth, all with the support of our seasoned team. Our comprehensive range of services includes branding that resonates, development that delivers, and marketing that captivates, encompassing every element of your digital journey.
              <br/>
              <br/>
              With Innovixia, you'll experience flawless integration and expert execution at every stage, ensuring your success in the ever-evolving digital landscape. Join us on this exciting venture as we shape your digital future together.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_img_1.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      {/* End About Section */}

      {/* Start Services Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Unleashing Your Digital Potential"
          subtitle="Why Innovixia?"
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="45" />
        <ServiceList />
      </Div>
      {/* End Services Section */}

    {/* Start Leader Section */}
    <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Exceptional<br/>Founders' Circle"
          subtitle="Our Leadership"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <Leader />
      </Div>
    {/* End Team Section */}


    {/* Start Team Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Meet the Minds<br/>Behind Our Mission"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div>
      <Spacing lg="150" md="80" />
    {/* End Team Section */}




      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Elevate Your Online Presence<br />with Our Expertise"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
