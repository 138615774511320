import React from 'react';
import './leadership.scss';


const TeamMember = ({ imgSrc, name, role , socialLinks }) => (
    <div class="col-md-4 col-sm-6">
        <div class="our-team">
            <div class="pic">
            <img src={imgSrc} alt={name} />
                <ul className="social-links">
                    {socialLinks.twitter && <li><a href={socialLinks.twitter} target="_blank" className="fab fa-twitter"></a></li>}
                    {socialLinks.google && <li><a href={socialLinks.google} target="_blank" className="fab fa-google-plus"></a></li>}
                    {socialLinks.linkedin && <li><a href={socialLinks.linkedin} target="_blank" className="fab fa-linkedin"></a></li>}
                    {socialLinks.facebook && <li><a href={socialLinks.facebook} target="_blank" className="fab fa-facebook"></a></li>}
                </ul>
            </div>
            <div class="team-content">
                <h3 class="title">{name}</h3>
                <span class="post">{role}</span>
            </div>
        </div>
    </div>
);

const leadership = () => (
  <div className="container">
    <div className="row">
      <TeamMember imgSrc="images/james.jpeg" name="JAMES SAVANT" role="President"         socialLinks={{
          linkedin: 'https://www.linkedin.com/in/jaymesavant/',
          facebook: 'https://www.facebook.com/ssmmaallss'
        }} />
      <TeamMember imgSrc="images/HAIDER.jpg" name="Haider Shafique" role="Sr. Product Design Engineer"         socialLinks={{
          linkedin: 'https://www.linkedin.com/in/haidershafique/'
        }} />
      <TeamMember imgSrc="images/mohsin1.png" name="Mohsin Razzaq" role="Sr. Product Development Engineer"         socialLinks={{
          linkedin: 'https://www.linkedin.com/in/mohsin-razzaq/'
        }} />                
    </div>
  </div>
);

export default leadership;