import React, { useEffect } from 'react';
import Div from '../Div';
import Hero5 from '../Hero/Hero5';
import LogoList from '../LogoList';
import Spacing from '../Spacing';
import { pageTitle } from '../../helper';
import SectionHeading from '../SectionHeading';
import TestimonialSlider from '../Slider/TestimonialSlider';
import FunFact from '../FunFact';
import Card from '../Card';
import TeamSlider from '../Slider/TeamSlider';
import Leader from '../leadership/leadership';
import PortfolioSlider from '../Slider/PortfolioSlider';
import { Icon } from '@iconify/react';
import Logos from '../Logos';
export default function MarketingAgencyHome() {
  pageTitle('Home');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroSocialLinks = [
    {
      name: 'Linkedin',
      links: 'https://www.linkedin.com/company/innovixia/',
    },
  ];
  const funfaceData = [
    {
      title: 'Satisfied Clients',
      factNumber: '100',
    },
    {
      title: 'Project completed',
      factNumber: '150',
    },
    {
      title: 'Team members',
      factNumber: '15',
    },
    {
      title: 'Years of experience',
      factNumber: '15',
    },
  ];
  return (
    <>
      {/* Start Hero Section */}
      <Hero5
        title="Tailored Solutions for<br /> Every Business"
        subtitle="We deliver best problem solving solution for our client and provide finest finishing product in present and upcoming future."
        btnLink="contact"
        btnText="Let’s talk"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      <Div className="cs-video_block_1_wrap">
      <div className="container" style={{marginTop:"100px"}}>
        <FunFact
          variant="cs-type1"
          title="Powering Success"
          subtitle="Our dedication to delivering outstanding digital marketing solutions has led to over 100 satisfied clients. Join the ranks of success with us!"
          data={funfaceData}
        />
      </div>
      </Div>
      {/* End FunFact Section */}

            {/* Start Why Choose Section */}
            <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/aboutushome.jpg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Crafting Digital Dreams"
              subtitle="Our Journey and Vision"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              We empower businesses, from startups to enterprises, with tailored digital solutions. Elevate your digital presence, streamline operations, and fuel growth with our expert team by your side.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
              Our offerings span branding, development, and marketing, covering every facet of your digital triumph. Experience seamless integration and expert delivery at every step.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}

      {/* Start Service Section */}
      <Spacing lg="150" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Services we can help you with"
                subtitle="What Can We Do"
                btnText="See All Services"
                btnLink="/service"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Branding"
                    link="/service"
                    src="/images/service_1.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Web Development"
                    link="/service"
                    src="/images/service_2.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="App Development"
                    link="/service"
                    src="/images/service_3.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Sales Automation"
                    link="/service"
                    src="/images/service_4.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}


      {/* Start LogoList Section */}
      <Spacing lg="150" md="180" />
      <Div className="overflow-hidden">
        <SectionHeading
          title="Our Clients Love Us"
          subtitle="Beloved Clients"
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="45" />
        <Logos />
      </Div>
      <Spacing lg="150" md="80" />
      {/* End LogoList Section */}

      {/* Start Portfolio Section */}
      <Div className="overflow-hidden" >
        <Div className="container">
          <SectionHeading
            title="Portfolio to explore"
            subtitle="Latest Projects"
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioSlider />
      </Div>
      {/* End Portfolio Section */}

    {/* Start Team Section */}
    <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Exceptional<br/>Founders' Circle"
          subtitle="Our Leadership"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <Leader />
      </Div>
    {/* End Team Section */}

    {/* Start Team Section */}
    <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Meet the Minds<br/>Behind Our Mission"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div>
      <Spacing lg="150" md="80" />
      {/* End Team Section */}

      
      

      {/* Start Testimonial Section */}
      <TestimonialSlider />
      {/* End Testimonial Section */}



{/* Start Contact Section */}
<Spacing lg="140" md="70" />
      <Div className="container">
        <SectionHeading
          title="Getting touch"
          subtitle="Contact Us"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
      </Div>
      <Div className="cs-gradient_bg_1">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <Spacing lg="100" md="80" />
              <form action="#" className="row">
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Full Name*</label>
                  <input type="text" className="cs-form_field" />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Email*</label>
                  <input type="text" className="cs-form_field" />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Project Type*</label>
                  <input type="text" className="cs-form_field" />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-6">
                  <label className="cs-primary_color">Mobile*</label>
                  <input type="text" className="cs-form_field" />
                  <Spacing lg="20" md="20" />
                </Div>
                <Div className="col-sm-12">
                  <label className="cs-primary_color">Mobile*</label>
                  <textarea
                    cols="30"
                    rows="7"
                    className="cs-form_field"
                  ></textarea>
                  <Spacing lg="25" md="25" />
                </Div>
                <Div className="col-sm-12">
                  <button className="cs-btn cs-style1">
                    <span>Send Message</span>
                    <Icon icon="bi:arrow-right" />
                  </button>
                </Div>
              </form>
              <Spacing lg="100" md="60" />
            </Div>
            <Div className="col-lg-6">
              <Div
                className="cs-google_map cs-type1 cs-bg"
                data-src="assets/img/map_img_1.jpeg"
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57305.405545471236!2d-81.83573951007463!3d26.144978190957346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88dae19b73c2d7ad%3A0x673f5318d72c9aaa!2sNaples%2C%20FL%2C%20USA!5e0!3m2!1sen!2s!4v1692050617847!5m2!1sen!2s"
                  allowFullScreen
                  title="Google Map"
                />
              </Div>
              <Spacing lg="0" md="80" />
            </Div>
          </Div>
        </Div>
      </Div>
      {/* Start Contact Section */}






    </>
  );
}
