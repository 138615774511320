import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Div from '../Div';

export default function Accordion(props) {
  const [selected, setSelected] = useState(0);

  const handleToggle = (index) => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  return (
    <Div className="cs-accordians cs-style1">
      {props.accordionData.map((item, index) => (
        <Div className={`cs-accordian ${selected === index ? 'active' : ''}`} key={index}>
          <Div className="cs-accordian_head" onClick={() => handleToggle(index)}>
            <h2 className="cs-accordian_title">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>
            </span>
          </Div>
          <Div className="cs-accordian_body">
            <Div className="cs-accordian_body_in">{item.answer}</Div>
          </Div>
        </Div>
      ))}
    </Div>
  );
}

Accordion.propTypes = {
  accordionData: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    })
  ).isRequired,
};