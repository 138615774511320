import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Accordion from '../Accordion';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { Icon } from '@iconify/react';
import './custom.scss';
import MovingText2 from '../MovingText/MovingText2';


export default function ServiceDetailsPage1() {
  pageTitle('Branding Service');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const yourAccordionData = [
    {
      question: "What is brand architecture, and why is it important for my business?",
      answer: "Brand architecture is the strategic structuring of your brand portfolio. It's essential because it helps unify your products and services under a strong brand umbrella, enhancing customer recognition and trust while maximizing market potential.",
    },
    {
      question: "How can effective packaging design impact my eCommerce business?",
      answer: "Effective packaging design plays a critical role in influencing customer purchase decisions and enhancing the overall product experience. It can differentiate your brand, communicate key messages, and contribute to a memorable shopping journey, both online and offline.",
    },
    {
      question: "What sets Innovixia's rebranding services apart from others?",
      answer: "Our rebranding services offer a holistic approach, combining strategic guidance with exceptional design capabilities. We consider all aspects of your brand, from logo redesign to complete brand toolkits, and our expert consultants provide continuous support to ensure a seamless transition.",
    },
    {
      question: "How does Innovixia determine the right marketing channels for my brand?",
      answer: "We identify the most effective marketing channels through in-depth research and analysis, considering your target audience and market trends. Our custom marketing strategies are designed to maximize reach and impact across these chosen channels.",
    },
    {
      question: "What metrics does Innovixia use to measure the success of marketing campaigns?",
      answer: "We measure campaign effectiveness through a combination of data analysis, customer response, and Key Performance Indicator (KPI) tracking. These insights provide actionable data to optimize your brand marketing efforts and sustain online growth.",
    },
    // Add more accordion items as needed
  ];
  const serviceData1 = [
    {
      title: 'Minimalist Logo',
      href: '/service/service-details',
    },
    {
      title: 'Flat Designs',
      href: '/service/service-details',
    },
    {
      title: 'Vintage Style',
      href: '/service/service-details',
    },
    {
      title: 'Mascot Logo',
      href: '/service/service-details',
    },
    {
      title: 'Brand Style Guide',
      href: '/service/service-details',
    },
    {
      title: 'Brochure and Pamphlet Design',
      href: '/service/service-details',
    },
  ];
  const serviceData2 = [
    {
      title: 'Social Media Branding',
      href: '/service/service-details',
    },
    {
      title: 'Website Branding',
      href: '/service/service-details',
    },
    {
      title: 'Packaging Design',
      href: '/service/service-details',
    },
    {
      title: 'Brand Logo Animation',
      href: '/service/service-details',
    },
    {
      title: 'Business Card Design',
      href: '/service/service-details',
    },
    {
      title: 'Email Signature Design',
      href: '/service/service-details',
    },
  ];
  return (
    <>
      <PageHeading 
        title='Branding'
        bgSrc='/images/about_hero_bg.jpeg'
        pageLinkText={"Branding"}
      />
<Spacing lg='145' md='80'/>
<div class ="container" >
  <div class="row">
  <div class="right col-md-6">
  <SectionHeading
              title="Our Branding Services"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              From conceptualization to launch and beyond, our team of branding specialists and acclaimed designers provide a comprehensive suite of result-driven branding solutions.
              </p>
              <Spacing lg="45" md="40" />
            </SectionHeading>

            <SectionHeading
              title="Brand Strategy"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
             Innovixia excels in brand strategy, employing a tried-and-true approach of discovery and research to power your brand's online expansion. From crafting brand manuals and style directives to ensuring uniform design and collateral, our team of seasoned professionals boasts extensive experience in delivering premium branding strategies.
              <br/>
              <br/>
              Our method encompasses establishing precise brand positioning, crafting distinctive brand identities, formulating compelling brand messaging, ensuring consistent brand experiences, and offering strategic guidance for brand advancement.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <SectionHeading
              title="Brand Book & Style Guide"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Maintaining brand uniformity is crucial for captivating and retaining your audience. Through a brand manual and style manual, your brand's visual communication maintains its coherence across all interactions, whether online or in print.
              <br/>
              <br/>
              At Innovixia, we craft tailored brand manuals and style guides, complete with comprehensive usage instructions for your logo, distinctive typography, visual components, and beyond. This guarantees uniformity throughout your collateral and promotional materials.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <SectionHeading
              title="Custom Logo Design"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Innovixia's logo design methodology is meticulously crafted to ensure your brand identity shines brightly in the digital realm. Your logo serves as the initial point of contact and the visual representation of your business.
              <br/>
              <br/>
              With Innovixia, you'll embark on a fluid and collaborative creative journey that melds our proficiency with your distinct perspectives. Have faith in our team of acclaimed designers to lead you through this transformative journey, resulting in a truly unique custom logo design.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <SectionHeading
              title="Brand Activation & Management"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Innovixia provides an extensive range of brand activation and management services, covering the strategic execution of marketing endeavors to captivate and connect with your intended audience.
              <br/>
              <br/>
              This service is paramount for safeguarding your brand's reputation, equity, and worth, all of which we attain through steadfast brand positioning, identity crafting, messaging, guidelines, ongoing monitoring, and adaptability.
              <br/>
              <br/>
              Our forte lies in devising potent strategies, generating compelling collateral, and executing pinpointed campaigns, enabling brands to establish a robust online presence.
              <br/>
              <br/>
              Furthermore, we conduct comprehensive brand assessments, assist in forging strategic partnerships, and even offer guidance in implementing employee training programs to effectively propagate your brand.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <SectionHeading
              title="Brand Architecture"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Innovixia specializes in expert brand architecture services, leveraging industry-leading practices and established branding strategies. Our objective is to consolidate your products and services beneath a sturdy and impactful brand framework.
              <br/>
              <br/>
              We adopt a meticulous approach to harmonizing your brand portfolio, ensuring a seamless brand encounter at every juncture.
              <br/>
              <br/>
              In formulating your brand architecture strategy, we account for your brand's hierarchy, customer perception, scalability, uniformity, internal synchronization, and the competitive landscape. This results in a framework that bolsters brand expansion and optimizes market potential.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <SectionHeading
              title="Brand Package Designs"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Innovixia extends its expertise in crafting brand package designs tailored for B2C and eCommerce brands. Product packaging assumes a pivotal role in shaping the holistic product encounter, wielding the power to sway customer purchase choices in mere seconds.
              <br/>
              <br/>
              Whether it's on a store shelf or during the online shopping expedition, your brand package designs function as a critical conduit of communication.
              <br/>
              <br/>
              Our team of acclaimed designers not only considers the overarching design requisites but also draws upon your brand manual, style guide, and industry best practices to ensure effective brand and product communication.  
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <SectionHeading
              title="Professional Rebranding Services"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Innovixia's all-encompassing rebranding solutions amalgamate strategic direction with remarkable design prowess.
              <br/>
              <br/>
              Whether it's revitalizing your logo or crafting an entire brand toolkit, we offer a comprehensive rebranding service that resonates across all your marketing and communication platforms.
              <br/>
              <br/>
              This encompasses the rejuvenation of current assets, logo reimagining, and all materials in demand of a refresh.
              <br/>
              <br/>
              Once we've delineated your objectives and charted the path to your rebranding journey, our adept branding consultants remain by your side throughout the process, ensuring a smooth transition.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <SectionHeading
              title="Brand Marketing"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Innovixia aids in pinpointing the channels with the greatest reach potential, enabling you to captivate a vast audience and convert numerous customers and clients.
              <br/>
              <br/>
              We construct tailor-made marketing strategies founded on extensive market and audience research.
              <br/>
              <br/>
              Our multi-faceted marketing approach guarantees optimal reach and influence. We gauge the efficacy of our strategies through meticulous data analysis, customer feedback, and KPI monitoring, offering actionable insights to fine-tune your brand's marketing endeavors and sustain its online growth.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
    </div>
    <div class="left col-md-4 sticky-top branding-form">
    <div class="form-branding">
      <h4>Request A Proposal</h4>
      <p>Enhance Your Branding With Digital Silk</p>
    <form action="#" className="row">
              <Div className="col-sm-30">
              <label className="cs-primary_color">Name*</label>
                <input type="text" className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-30">
                <label className="cs-primary_color">Email*</label>
                <input type="text" className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-30">
                <label className="cs-primary_color">Mobile*</label>
                <input type="text" className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Message*</label>
                <textarea
                  cols="30"
                  rows="4"
                  className="cs-form_field"
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button className="cs-btn cs-style1">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
            </div>
    </div>

  </div>
</div>
      <Spacing lg='145' md='80'/>
      
      {/* Start Moving Text Section */}
     <MovingText2 data={serviceData1} />
    <Spacing lg="20" md="10" />
      <MovingText2 reverseDirection data={serviceData2} />
      {/* End Moving Text Section */}
     

      <Spacing lg='145' md='80'/>
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
            <Accordion accordionData={yourAccordionData} />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
