import React from 'react'
import Div from '../Div'
import Marquee from "react-fast-marquee";
import './logos.scss'


export default function Logos() {
  const partnerLogos = [
    {
      src: '/images/acommercial.png', 
      alt:'Partner'
    },
    {
      src: '/images/ccg.png', 
      alt:'Partner'
    },
    {
      src: '/images/client2.png', 
      alt:'Partner'
    },
    {
      src: '/images/client1.jpg', 
      alt:'Partner'
    },
    {
      src: '/images/milesofjunk.png', 
      alt:'Partner'
    }
  ]
  return (
    <Div className="cs-partner_logo_wrap">
    <Marquee
        gradient={false}
        direction="right"
        style={{ margin: "50px auto" }}
    >
        {partnerLogos.map((logo, index) => (
          <img key={index} src={logo.src} alt={logo.alt} />
        ))}
      </Marquee>
    </Div>
  )
}
