import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Accordion from '../Accordion';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { Icon } from '@iconify/react';
import './custom.scss';
import MovingText2 from '../MovingText/MovingText2';


export default function ServiceDetailsPage3() {
  pageTitle('Sales Automation');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const yourAccordionData = [
    {
      question: "What are Sales Solutions, and how can they benefit my business?",
      answer: "Sales Solutions are tools and strategies designed to optimize and streamline your sales operations. They can benefit your business by improving efficiency, enhancing lead generation, and ultimately increasing your sales and revenue.",
    },
    {
      question: "How are the Sales Strategies customized for my business?",
      answer: "We tailor our sales strategies to meet the specific needs and goals of your business. Through consultation and analysis, we create strategies that align with your unique requirements, ensuring a more effective and targeted approach.",
    },
    {
      question: "Can you explain the role of data-driven insights in sales?",
      answer: "Data-driven insights enable you to make informed decisions based on real data. In the context of sales, these insights help refine your strategies, identify trends, and improve your overall sales performance.",
    },
    {
      question: "What kind of support and training do you provide for our team?",
      answer: "We offer dedicated support and training to help your team make the most of our services. This includes onboarding, training sessions, and ongoing assistance to ensure you fully leverage our solutions for your business success.",
    }
    // Add more accordion items as needed
  ];
  const serviceData1 = [
    {
      title: 'Lead Generation',
      href: '/service/service-details',
    },
    {
      title: 'Data Aggregation',
      href: '/service/service-details',
    },
    {
      title: 'Email Campaign Management',
      href: '/service/service-details',
    },
    {
      title: 'List Cleaning',
      href: '/service/service-details',
    },
  ];
  const serviceData2 = [
    {
      title: 'Email Template Design',
      href: '/service/service-details',
    },
    {
      title: 'Email List Building',
      href: '/service/service-details',
    },
    {
      title: 'Email Copywriting',
      href: '/service/service-details',
    },
    {
      title: 'Subscriber Growth Strategies',
      href: '/service/service-details',
    },
  ];
  return (
    <>
      <PageHeading 
        title='Sales Automation'
        bgSrc='/images/about_hero_bg.jpeg'
        pageLinkText={"Sales Automation"}
      />
<Spacing lg='145' md='80'/>
<div class ="container">
  <div class="row">
  <div class="right col-md-6">
  <SectionHeading
              title="Our Sales Automation Solutions"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Explore how our Sales Solutions can help you optimize and simplify your sales operations, making it easier for your team to achieve efficiency and close deals effectively.
              </p>
              <Spacing lg="45" md="40" />
            </SectionHeading>

            <SectionHeading
              title="Tailored Sales Strategies"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Our sales strategies are not one-size-fits-all. We work closely with you to create personalized sales strategies that are tailored to your specific business requirements. This ensures that your sales efforts are uniquely optimized for success.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <SectionHeading
              title="Efficient Lead Generation"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              We simplify the lead generation process for you. Our solutions make it easier to identify potential customers, allowing you to save valuable time and resources that can be better utilized for converting leads into sales.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <SectionHeading
              title="Sales Workflow Enhancement"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Our services focus on enhancing your entire sales workflow. From the initial contact with prospects to the final conversion, we help you maximize efficiency at every step, ensuring that no opportunity is missed.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <SectionHeading
              title="Data-Driven Sales Insights"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Our data-driven approach provides you with valuable insights that empower data-driven decision-making. We help you leverage the power of data to refine your sales strategy and boost overall sales performance.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <SectionHeading
              title="Scalable Solutions"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Our solutions are scalable, which means they can grow alongside your business. As your business expands, our flexible approach ensures that our services can adapt to your evolving needs seamlessly.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <SectionHeading
              title="Seamless Integration"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              We make sure that our solutions integrate smoothly into your existing systems and processes. This ensures a seamless transition without disruptions, so you can start benefiting from our services right away.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <SectionHeading
              title="Dedicated Support and Training"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              We provide dedicated support and training to assist your team in effectively using our solutions. Our experts are here to guide you every step of the way, ensuring that you get the most out of our services.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
    </div>
    <div class="left col-md-4 sticky-top branding-form">
    <div class="form-branding">
      <h4>Request A Proposal</h4>
      <p>Enhance Your Branding With Digital Silk</p>
    <form action="#" className="row">
              <Div className="col-sm-30">
              <label className="cs-primary_color">Name*</label>
                <input type="text" className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-30">
                <label className="cs-primary_color">Email*</label>
                <input type="text" className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-30">
                <label className="cs-primary_color">Mobile*</label>
                <input type="text" className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Message*</label>
                <textarea
                  cols="30"
                  rows="4"
                  className="cs-form_field"
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button className="cs-btn cs-style1">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
            </div>
    </div>

  </div>
</div>
      <Spacing lg='145' md='80'/>
      
      {/* Start Moving Text Section */}
     <MovingText2 data={serviceData1} />
    <Spacing lg="20" md="10" />
      <MovingText2 reverseDirection data={serviceData2} />
      {/* End Moving Text Section */}
     

      <Spacing lg='145' md='80'/>
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
            <Accordion accordionData={yourAccordionData} />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
