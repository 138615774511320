import React from 'react'
import PricingTable from '.'
import Section from '../Div'
import Spacing from '../Spacing'

export default function PricingTableList() {
  return (
    <Section className="position-relative">
      <Section className="row">
        <Section className="col-lg-4">
            <PricingTable 
              title='Starter'
              price='1000'
              currency='$'
              features={['Branding', 'Logo', 'Brand Style Guide', 'Website', 'Copywriting', 'Hosting for 1 Year']}
              btnText='Contact Now'
              btnLink='/contact'
              btype="Basic Business"
            />
          <Spacing lg='25' md='25'/>
        </Section>
        <Section className="col-lg-4">
            <PricingTable 
              title='Professional'
              price='1500'
              currency='$'
              timeline='monthly'
              features={['Branding', 'Logo', 'Brand Style Guide', 'Ecommerce Website', 'Copywriting', 'Hosting for 1 Year']}
              btnText='Contact Now'
              btnLink='/contact'
              btype="Ecommerce Business"
            />
          <Spacing lg='25' md='25'/>
        </Section>
        <Section className="col-lg-4">
            <PricingTable 
              title='Enterprise'
              price='Contact Sales'
              currency=''
              timeline='monthly'
              features={['Logo', 'Brand Style Guide', 'Stationery', 'Social Media Banners', 'UI/UX', 'Fully Customize Web APP']}
              btnText='Contact Now'
              btnLink='/contact'
              btype="Custom Web Application"
            />
          <Spacing lg='25' md='25'/>
        </Section>
      </Section>
    </Section>
  )
}
