import { Route, Routes } from 'react-router-dom';
import AboutPage from './components/Pages/AboutPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import PortfolioDetailsPage from './components/Pages/PortfolioDetailsPage';
import ServiceDetailsPage1 from './components/Pages/ServiceDetailsPage1';
import ServiceDetailsPage2 from './components/Pages/ServiceDetailsPage2';
import ServiceDetailsPage3 from './components/Pages/ServiceDetailsPage3';
import ServicesPage from './components/Pages/ServicesPage';
import PortfolioPage from './components/Pages/PortfolioPage';
import MarketingAgencyHome from './components/Pages/MarketingAgencyHome';
import Layout from './components/Layout';
import { useLayoutEffect,useState } from 'react';
import gsap from "gsap";

function App() {



  useLayoutEffect(() => {


    var tl = gsap.timeline()

    let ctx = gsap.context(() => {tl.from("#loader h3",{
      x:40,
      opacity: 0,
      duration: 2,
      stagger: 0.1
    } )

  });
  tl.to("#loader h3",{
    opacity:0,
    x:-20,
    duration: 1,
    stagger: 0.1
  })

  tl.to("#loader",{
    opacity:0,
  })

  tl.to("#loader",{
    display: "none",
  })



  return () => ctx.revert();

  



  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<MarketingAgencyHome />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="service" element={<ServicesPage />} />
          <Route
            path="services/branding"
            element={<ServiceDetailsPage1 />}
          />
          <Route
            path="services/web-design"
            element={<ServiceDetailsPage2 />}
          />
          <Route
            path="services/sales-automation"
            element={<ServiceDetailsPage3 />}
          />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route
            path="portfolio/:portfolioDetailsId"
            element={<PortfolioDetailsPage />}
          />
          <Route path="contact" element={<ContactPage />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
