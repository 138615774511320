import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import PricingTableList from '../PricingTable/PricingTableList'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import ServiceCard from '../ServiceCard/ServiceCard';

export default function ServicesPage() {
  pageTitle('Service');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Services'
        bgSrc='images/about_hero_bg.jpeg'
        pageLinkText='Services'
      />




      <Spacing lg='150' md='80'/>
      <Div className='container'>
      <SectionHeading
          title='Tailored Solutions to<br/> Fuel Your Success' 
          subtitle='Our Services'
        />
        <Spacing lg='85' md='40'/>
      <div className="row justify-content-center">
      <ServiceCard
          title={"Search Engine Marketing"}
          desc={
            "Search engine marketing is one of the best ways to ensure your next client finds you on search engine results pages."
          }
          icon={
            <i
              style={{ fontSize: 80 }}
              class="fa-brands fa-searchengin fa-2xl"
            ></i>
          }
        />
        <ServiceCard
          title={"Data Aggregation"}
          desc={
            "Efficiently bringing data together from multiple sources and expressed in a summary form for statistical analysis."
          }
          icon={
            <i style={{ fontSize: 80 }} class="far fa-object-group fa-2xl"></i>
          }
        />
        <ServiceCard
          title={"Email Marketing"}
          desc={
            "Email marketing gives you full control and allows you to establish direct contact with your customers."
          }
          icon={
            <i style={{ fontSize: 80 }} class="fas fa-mail-bulk fa-2xl"></i>
          }
        />
        <ServiceCard
          title={"Email Deliverability"}
          desc={
            "We create best in class email setups, that get your emails to where they are going, 99% inbox primary's and managed reputations."
          }
          icon={
            <i
              style={{ fontSize: 80 }}
              class="fa-solid fa-envelope-open-text fa-2xl"
            ></i>
          }
        />
        <ServiceCard
          title={"Email Servers"}
          desc={
            "An Email server is always the most reliable way to reach who you need to, we plot your goals with email and make sure you reach the inbox."
          }
          icon={<i style={{ fontSize: 80 }} class="fas fa-server fa-2xl"></i>}
        />
        <ServiceCard
          title={"Reputation Management"}
          desc={
            "Your domain and IP's have a reputation, we manage it for you to make sure you don't work with bad providers keeping you inboxing with who you need."
          }
          icon={<i style={{ fontSize: 80 }} class="far fa-file-alt fa-2xl"></i>}
        />
        <ServiceCard
          title={"Social Media Marketing"}
          desc={
            "We build long term relationships between people and brands through social interactions."
          }
          icon={
            <i
              style={{ fontSize: 80 }}
              class="fa-solid fa-square-share-nodes fa-2xl"
            ></i>
          }
        />
        <ServiceCard
          title={"Sales Automation"}
          desc={
            "Sales automation helps you sell more in less time, as well as build and automate sales processes."
          }
          icon={
            <i
              style={{ fontSize: 80 }}
              class="fa-solid fa-arrow-up-right-dots fa-2xl"
            ></i>
          }
        />
        <ServiceCard
          title={"Lead Generation"}
          desc={
            "Transform a passive or stagent prospect list into upfront, functional sales lead."
          }
          icon={
            <i style={{ fontSize: 80 }} class="fa-solid fa-users fa-2xl"></i>
          }
        />
        <ServiceCard
          title={"Graphic Design"}
          desc={
            "We guarantee that all network delivered by Onlyhauling would be 100% creative, and will help you to uplift your brand."
          }
          icon={
            <i
              style={{ fontSize: 80 }}
              class="fa-solid fa-paintbrush fa-2xl"
            ></i>
          }
        />
        <ServiceCard
          title={"Buisness Websites"}
          desc={
            "We create a visual hierarchy to highlight your important site elements and encourage page visitors to take your desired action."
          }
          icon={
            <i style={{ fontSize: 80 }} class="fa-solid fa-globe fa-2xl"></i>
          }
        />
        <ServiceCard
          title={"Google My Business"}
          desc={
            "Optimize your GMB listing with Onlyhauling Google My Business Audit. Benchmark against competitors and find insights to supercharge your GMB"
          }
          icon={
            <i style={{ fontSize: 80 }} class="fa-brands fa-google fa-2xl"></i>
          }
        />
        <ServiceCard
          title={"Web Apps Development"}
          desc={
            "Productive & engaging web solutions for smarter work and improved customer service."
          }
          icon={
            <i style={{ fontSize: 80 }} class="fa-solid fa-computer fa-2xl"></i>
          }
        />
        <ServiceCard
          title={"Mobile Apps Development"}
          desc={
            "We create an impactful mobile apps ( Android / IOS ) that fits your brand and industry within a shorter time frame."
          }
          icon={
            <i style={{ fontSize: 80 }} class="fa-solid fa-mobile fa-2xl"></i>
          }
        />
        <ServiceCard
          title={"BlockChain Development"}
          desc={
            "Advanced decentralized solutions that bring scalability, transparency, and security to your business."
          }
          icon={
            <i style={{ fontSize: 80 }} class="fa-solid fa-server fa-2xl"></i>
          }
        />
        <ServiceCard
          title={"Local Directory Entries"}
          desc={
            "Create 350 directory entries with companies throughout the local area and the United States. This results in improved SEO results."
          }
          icon={
            <i style={{ fontSize: 80 }} class="fa-regular fa-folder fa-2xl"></i>
          }
        />
        <ServiceCard
          title={"Backlink Development"}
          desc={
            "A backlinking profile is one of the most important aspects of your website's ability to rank organically in search engines."
          }
          icon={
            <i style={{ fontSize: 80 }} class="fa-solid fa-link fa-2xl"></i>
          }
        />
        <ServiceCard
          title={"Help & Support"}
          desc={
            "Our Customer Service team will be pleased to assist you anytime you have a query."
          }
          icon={
            <i
              style={{ fontSize: 80 }}
              class="fa-solid fa-handshake-angle fa-2xl"
            ></i>
          }
        />
        </div>
      </Div>
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <SectionHeading
          title='Choose the Perfect<br/>Package for Your Needs' 
          subtitle='Explore Our Pricing Plans'
        />
        <Spacing lg='85' md='40'/>
        <PricingTableList/>
      </Div>
      <Spacing lg='125' md='55'/>
      <TestimonialSlider/>
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Elevate Your Online Presence<br />with Our Expertise' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
