import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Accordion from '../Accordion';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import { Icon } from '@iconify/react';
import './custom.scss';
import MovingText2 from '../MovingText/MovingText2';


export default function ServiceDetailsPage2() {
  pageTitle('Web Design');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const yourAccordionData = [
    {
      question: "What does 'custom web design' mean, and why is it important for my website?",
      answer: "Custom web design refers to the creation of a unique and tailored website that suits your specific needs and brand identity. It's essential because it ensures that your website stands out, offers an excellent user experience, and aligns with your business goals.",
    },
    {
      question: "Can you explain the concept of scalability in custom web design?",
      answer: "Scalability in custom web design means that your website is designed to grow and adapt to changing needs and demands. A scalable website can handle increased traffic, additional features, and content expansion without major redesign or performance issues.",
    },
    {
      question: "What role does user experience (UX) and user interface (UI) design play in website development?",
      answer: "UX design focuses on creating a positive and efficient user experience, while UI design deals with the visual elements and layout of your website. Together, they ensure that your website is user-friendly, visually appealing, and easy to navigate, which is essential for attracting and retaining visitors.",
    },
    {
      question: "What quality assurance measures are taken to ensure a bug-free website during development?",
      answer: "Our quality assurance process involves rigorous testing of the website to identify and rectify any bugs, glitches, or issues. This ensures that your website functions smoothly, providing a seamless experience for users.",
    },
    {
      question: "How can I get started with your custom web design services, and what is the typical timeline for a project?",
      answer: "To get started, you can reach out to us for a consultation. The project timeline varies depending on the complexity of your website and specific requirements. We'll provide a detailed project plan during the initial discussions to give you an estimate of the timeline and deliverables.",
    },
    // Add more accordion items as needed
  ];
  const serviceData1 = [
    {
      title: 'Custom Business Website Design',
      href: '/service/service-details',
    },
    {
      title: 'Responsive Web Design',
      href: '/service/service-details',
    },
    {
      title: 'E-commerce Integration',
      href: '/service/service-details',
    },
    {
      title: 'Wordpress Websites',
      href: '/service/service-details',
    },
  ];
  const serviceData2 = [
    {
      title: 'Content Management System',
      href: '/service/service-details',
    },
    {
      title: 'Website Maintenance',
      href: '/service/service-details',
    },
    {
      title: 'Google Analytics Integration',
      href: '/service/service-details',
    },
    {
      title: 'Content Migration',
      href: '/service/service-details',
    },
  ];
  return (
    <>
      <PageHeading 
        title='Website Design'
        bgSrc='/images/about_hero_bg.jpeg'
        pageLinkText={"Website Design"}
      />
<Spacing lg='145' md='80'/>
<div class ="container">
  <div class="row">
  <div class="right col-md-6">
  <SectionHeading
              title="Our Website Design Services"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Innovixia is a full-service agency featuring top web designers from across the globe. Whether you're starting a website from the ground up or revitalizing your existing digital footprint, our web design offerings encompass:
              </p>
              <Spacing lg="45" md="40" />
            </SectionHeading>

            <SectionHeading
              title="Custom Web Design"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Utilize the skills of our designers to craft a completely personalized website for your brand. Our bespoke web design services encompass research, analysis, strategic planning, tailored UX and UI design, and SEO optimization to boost traffic to your site.
              <br/>
              <br/>
              From branded animations to tailor-made iconography and a strategically devised conversion funnel, our design team collaborates closely with your website strategist to create a customized design that enhances.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <SectionHeading
              title="Custom Website Redesign"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Reimagine your digital footprint with expert guidance from our web designers and digital strategists.
              <br/>
              <br/>
              Our website revamp services encompass strategic website planning, bespoke UX and UI design, and search engine optimization aimed at enhancing your ranking and visibility.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <SectionHeading
              title="Custom eCommerce Web Design"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              We'll assist you in selecting the ideal platform to position your e-store for growth and create a personalized design that delivers an exceptional user experience, seamless checkout, multiple payment options, and other essential features for eCommerce web design.
              <br/>
              <br/>
              Our eCommerce experts will stand by your side throughout the entire e-store design or redesign journey, whether it involves e-store design and development, eCommerce marketing, business or tech consulting, or hosting and maintenance.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <SectionHeading
              title="Custom Creative Web Design"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Beyond a vibrant UI and cutting-edge animations, creative web design at Innovixia embodies the deliberate, strategic, and efficient utilization of creative design elements, be it color, animation, typography, imagery, or video.
              <br/>
              <br/>
              At Innovixia, your color palette will convey your brand identity, your typography will adhere to ADA compliance standards while staying on-brand, your animations and videos will enhance user engagement and dwell time, and your imagery will infuse a personalized, human touch. All creative elements will work in harmony to serve the overarching objectives of your website.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <SectionHeading
              title="Custom End-To-End Development"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Scalable, on-brand, user-friendly for administrators, and fully optimized, we create tailor-made websites on all leading industry platforms.
              <br/>
              <br/>
              Harness the power of our comprehensive custom web design services, encompassing meticulous website planning, search engine optimization, personalized UX and UI design, development, and quality assurance.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
    </div>
    <div class="left col-md-4 sticky-top branding-form">
    <div class="form-branding">
      <h4>Request A Proposal</h4>
      <p>Enhance Your Branding With Digital Silk</p>
    <form action="#" className="row">
              <Div className="col-sm-30">
              <label className="cs-primary_color">Name*</label>
                <input type="text" className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-30">
                <label className="cs-primary_color">Email*</label>
                <input type="text" className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-30">
                <label className="cs-primary_color">Mobile*</label>
                <input type="text" className="cs-form_field" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Message*</label>
                <textarea
                  cols="30"
                  rows="4"
                  className="cs-form_field"
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button className="cs-btn cs-style1">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
            </div>
    </div>

  </div>
</div>
      <Spacing lg='145' md='80'/>
      
      {/* Start Moving Text Section */}
     <MovingText2 data={serviceData1} />
    <Spacing lg="20" md="10" />
      <MovingText2 reverseDirection data={serviceData2} />
      {/* End Moving Text Section */}
     

      <Spacing lg='145' md='80'/>
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
            <Accordion accordionData={yourAccordionData} />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
