import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Portfolio from '../Portfolio';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function PortfolioPage() {
  pageTitle('Portfolio');
  const [active, setActive] = useState('all');
  const [itemShow, setItemShow] = useState(7);
  const [categoryMenu, setCategoryMenu] = useState([]);
  const [portfolioData, setPortfolioData] = useState([]); 
  
    useEffect(() => {

        fetch('https://api.innovixia.com/api/list/')
        .then(response => response.json())
        .then(data => setPortfolioData(data));

      // Fetch category menu data from the API
      fetch('https://api.innovixia.com/api/tag-list/')
        .then(response => response.json())
        .then(data => setCategoryMenu(data));

      // Scroll to the top on component mount
      window.scrollTo(0, 0);
    }, []);



  return (
    <>
      <PageHeading
        title="Portfolio"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="Portfolio"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Some recent work" subtitle="Our Portfolio" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === 'all' ? 'active' : ''}>
                <span onClick={() => setActive('all')}>All</span>
              </li>
              {categoryMenu.map((item, index) => (
                <li
                  className={active === item.name ? 'active' : ''}
                  key={index}
                >
                  <span onClick={() => setActive(item.name)}>
                    {item.name}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
  {portfolioData
    .filter(item => active === 'all' || item.tags.includes(active))
    .slice(0, itemShow)
    .map((item, index) => (
      <Div
        className={`${
          index === 3 || index === 6 ? 'col-lg-8' : 'col-lg-4'
        }`}
        key={index}
      >
        <Portfolio
          title={item.name}
          subtitle={item.title}
          href={`/portfolio/${item.slug}`}
          src={item.image_url}
          variant="cs-style1 cs-type1"
        />
        <Spacing lg="25" md="25" />
      </Div>
    ))}
</Div>

        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ''
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Elevate Your Online Presence<br />with Our Expertise"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
