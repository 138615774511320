import React from "react";
import styled from "styled-components";

/*eslint-disable */

function ServiceCard({ title, desc, icon }) {
  return (
    <ServiceCardStyled className="col-md-4 col-sm-6">
      <div className="s-item-con ">
        {icon}
        <h4>{title}</h4>
        <p>{desc}</p>
      </div>
    </ServiceCardStyled>
  );
}

const ServiceCardStyled = styled.section`
  width: 400px;
  margin: 20px;
  background: rgb(0,0,0);
  background: linear-gradient(331deg, rgba(0,0,0,1) 70%, rgba(98,20,168,1) 100%);
  text-align: center;
  padding: 3rem 1rem;
  border-radius: 7px;
  transition: all 0.4s ease-in-out;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.4rem;
    background: linear-gradient(153deg, rgba(155,29,250,1) 0%, rgba(138,21,255,1) 48%);
    transition: all 0.4s ease-in-out;
    transform: scale(0);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &:hover {
    box-shadow: 10px 10px 0px #f8f9fa14;
    transform: translateY(-5px);
    &::before {
      transform: scale(1);
    }
    i {
      background: #9b1dfa;
      background-clip: text;
      -webkit-background-clip: text;
      color: #9b1dfa;
      transition: 1s;
      -webkit-animation-name: fa-flip;
      animation-name: fa-flip;
      -webkit-animation-delay: var(--fa-animation-delay, 0);
      animation-delay: var(--fa-animation-delay, 0);
      -webkit-animation-direction: var(--fa-animation-direction, normal);
      animation-direction: var(--fa-animation-direction, normal);
      -webkit-animation-duration: var(--fa-animation-duration, 1s);
      animation-duration: var(--fa-animation-duration, 1s);
      -webkit-animation-iteration-count: var(
        --fa-animation-iteration-count,
        infinite
      );
      animation-iteration-count: var(--fa-animation-iteration-count, infinite);
      -webkit-animation-timing-function: var(
        --fa-animation-timing,
        ease-in-out
      );
      animation-timing-function: var(--fa-animation-timing, ease-in-out);
    }
  }
  .s-item-con {
    i {
      padding: 2rem;
      height: 100px;
    }
    h4 {
      font-size: 1.5rem;
      padding-bottom: 1rem;
    }
    p {
      padding-bottom: 1.5rem;
    }
  }
`;

export default ServiceCard;
