import { Icon } from '@iconify/react';
import React from 'react';
import Slider from 'react-slick';
import Div from '../Div';
import Team from '../Team';

export default function TeamSlider() {
  /** Team Member Data **/
  const teamData = [
    {
      memberImage: '/images/alex.jpg',
      memberName: 'Alexi Braun',
      memberDesignation: 'Chief Technology Officer',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/alexi-braun-529728148/',
      },
    },
    {
      memberImage: '/images/nicole.jpg',
      memberName: 'Nicole Lane, PMP',
      memberDesignation: 'Senior Business Advisor',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/lanenicole/',
      },
    },
    {
      memberImage: '/images/ABDULLAH.jpeg',
      memberName: 'Muhammad Abdullah',
      memberDesignation: 'Flutter Developer',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/muhammad-abdullah-aa2325235/',
      },
    },
    {
      memberImage: '/images/saad.jpg',
      memberName: 'Saad Ejaz',
      memberDesignation: 'Interior Designer',
      memberSocial: {
        linkedin: '/',
      },
    },
    {
      memberImage: '/images/Jessica.jpg',
      memberName: 'Jessica Reddington',
      memberDesignation: 'Program Manager',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/jessica-reddington-06606188/',
      },
    },
    {
      memberImage: '/images/Krysta.jpg',
      memberName: 'Krysta Alamazan',
      memberDesignation: 'Business Development Executive',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/krista-a-51a0a910/',
      },
    },
    {
      memberImage: '/images/Edlyn.jpg',
      memberName: 'Edlyn Colon, CSM',
      memberDesignation: 'Scrum Master',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/edlyn-colon-161426167/',
      },
    },
    {
      memberImage: '/images/shela.jpg',
      memberName: 'Shayla Barber, CSM',
      memberDesignation: 'SEO/SEM Specialist',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/shayla-barber-480335167/',
      },
    },
    {
      memberImage: '/images/Rafaela.jpg',
      memberName: 'Rafaela Simon, CSM',
      memberDesignation: 'Scrum Master',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/rafaela-simon-csm-11250a60/',
      },
    },
    {
      memberImage: '/images/ashlee.jpg',
      memberName: 'Ashlee Richey, CSM',
      memberDesignation: 'Scrum Master',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/ashlee-richey-023532240/',
      },
    },
    {
      memberImage: '/images/hifsa.jpg',
      memberName: 'Hifsa Shafique',
      memberDesignation: 'IT Coordinator',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/hifsa-shafique-5a812b263/',
      },
    },
    {
      memberImage: '/images/mahnoor.jpg',
      memberName: 'Mahnoor Malik',
      memberDesignation: 'IT Coordinator',
      memberSocial: {
        linkedin: 'https://www.linkedin.com/in/mahnoor-malik-59b809289/',
      },
    }     
  ];
  /** Slider Settings **/
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="cs-gap-24 cs-arrow_style2">
      {teamData.map((item, index) => (
        <Div key={index}>
          <Team
            memberImage={item.memberImage}
            memberName={item.memberName}
            memberDesignation={item.memberDesignation}
            memberSocial={item.memberSocial}
          />
        </Div>
      ))}
    </Slider>
  );
}
