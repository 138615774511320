import React, { useEffect , useState } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import Spacing from '../Spacing'

export default function PortfolioDetailsPage() {
  const params = useParams()
  const [portfolioDetails, setPortfolioDetails] = useState(null);
  



  useEffect(() => {
    const fetchPortfolioDetails = async () => {
      try {
        const response = await fetch(`https://api.innovixia.com/api/list/${params.portfolioDetailsId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setPortfolioDetails(data);
      } catch (error) {
        console.error('Error fetching portfolio details:', error);
      }
    };
    fetchPortfolioDetails();
    pageTitle('Portfolio Details');
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Portfolio Details'
        bgSrc='/images/about_hero_bg.jpeg'
        pageLinkText={portfolioDetails?.name}
      />
      <Spacing lg='150' md='80'/>
      <Div className="container">
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: portfolioDetails?.article }}
          ></div>
      </Div>
      <Spacing lg='145' md='80'/>
      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Elevate Your Online Presence<br />with Our Expertise"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  )
}
