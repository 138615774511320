import React, { useState, useEffect } from 'react'
import Portfolio from '../Portfolio'
import Div from '../Div'
import Slider from 'react-slick';


export default function PortfolioSlider() {
  const [portfolioData, setPortfolioData] = useState([]);

  useEffect(() => {
    // Replace 'YOUR_API_URL' with the actual API endpoint
    fetch('https://api.innovixia.com/api/featured-blogs/')
      .then(response => response.json())
      .then(data => setPortfolioData(data))
      .catch(error => console.error('Error fetching portfolio data:', error));
  }, []);
  
  /** Slider Settings **/
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 500,
    dots: true,
    arrows:false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  
  return (
    <Slider {...settings} className='cs-slider cs-style3 cs-gap-24 overflow-hidden'>
      {portfolioData.map((item, index)=> (
        <Div key={index}>
          <Portfolio 
            title={item.title} 
            subtitle={"Branding,Web Design"}
            href={`/portfolio/${item.slug}`}
            src={item.image_url}
          />
        </Div>
      ))}
    </Slider>
  )
}
